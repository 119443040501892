<template>
	<div>
		<main-header></main-header>
		<h1 class="page-title">로그인</h1>
		<login-form :isPopup="false"></login-form>
		<Footer />
	</div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';

export default {
	components: { LoginForm, MainHeader, Footer },
};
</script>

<style></style>
